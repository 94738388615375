<template>
  <v-container fluid id="SimulacoesConsulta" class="container-fundo pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">

      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

        <!-- Título da página -->
        <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100"
          ><span v-if="!lb_Funcao">Minhas </span> Simulações
        </v-toolbar-title>
        <!-- Fim Título da página -->

        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title
          class="mt-0 mb-6 mr-2 ml-4 pa-0"
          dark
          elevation="0"
        >
          <div :class="{ 'flex-wrap': isMobile }" class="d-flex" tabindex="-1">

          <!-- Text Field com Consulta ----------------------------------------->
          <v-container class="pa-0 ma-0 mb-7 d-flex " style="margin-top: -4px; max-width: 1200px;" >
              <v-menu
                id="vMenu"
                ref="vMenu"
                v-model="menu"
                bottom
                right
                fluid
                offset-y
                origin="top"
                class="pa-0 d-flex flex-wrap"
                elevation=0
                :close-on-content-click="false"
                @input="abriuConsulta"
              >
                <template v-slot:activator="{ on }">

                  <div
                    ref="vMenuDiv"
                    class="false-filter d-flex align-center"
                    align="center"
                    
                    v-on="on">
                    <v-icon class="false-icon ml-3 mr-2" color="#FFF">mdi-magnify</v-icon>
                    <div class="false-text flex-wrap" >
                      <v-chip
                        v-if="store_Simulacoes.filtro.cod_orcamento"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        {{store_Simulacoes.filtro.cod_orcamento}}
                      </v-chip>
                      <v-chip
                        v-if="store_Simulacoes.filtro.cliente_nome"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1">
                        {{store_Simulacoes.filtro.cliente_nome}}
                      </v-chip>
                      <v-chip
                        v-if="store_Simulacoes.filtro.empreendimento"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        {{store_Simulacoes.filtro.empreendimento.empreend_nome}}
                      </v-chip>
                      <v-chip
                        v-if="store_Simulacoes.filtro.data_inicial"
                        pill
                        
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        >= {{store_Simulacoes.filtro.data_inicial}}
                      </v-chip>
                      <v-chip
                        v-if="store_Simulacoes.filtro.data_final"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        &#60;= {{store_Simulacoes.filtro.data_final}}
                      </v-chip>
                      <v-chip
                        v-if="store_Simulacoes.filtro.corretor"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                        >
                        {{store_Simulacoes.filtro.corretor.corretor_nome}}
                      </v-chip>

                      <v-chip
                        v-if="store_Simulacoes.filtro.situacao"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        {{store_Simulacoes.filtro.situacao}}
                      </v-chip>                                                                                                                        
                    </div>
                    <v-icon class="false-icon mr-4 ml-2" color="#FFF">mdi-filter-variant-plus</v-icon>
                  </div>
                </template>
                <v-container 
                  :style="{ 'background-color':COR_SECUNDARIA_2 }"
                  style="min-width:800px!important; max-width:1000px!important; margin-bottom: -34px;">
                  <v-row no-gutters class="linhas">
                    <v-col md="2">
                      <!-- Cliente ------------------------ -->
                      <v-text-field
                        id="txtOrcamento"
                        ref="txtOrcamento"
                        dense
                        v-model="filtro_local.cod_orcamento"
                        cache-items
                        flat
                        label="Cod Orçamento"
                        placeholder="Cod Orçamento"
                        outlined
                        filled
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                        class="mr-2"
                        autofocus 
                      >
                      </v-text-field>
                    </v-col>
                    <v-col md="5">
                      <!-- Cliente ------------------------ -->
                      <v-text-field
                        id="txtCliente"
                        ref="txtCliente"
                        dense
                        v-model="filtro_local.cliente_nome"
                        cache-items
                        flat
                        label="Cliente"
                        placeholder="Cliente (nome ou cpf)..."
                        outlined
                        filled
                        background-color="#FFF"
                        clearable
                        tabindex="0"
                        class="mr-2"
                        autofocus 
                      >
                      </v-text-field>
                    </v-col>
                    <v-col md="5">
                      <!-- Empreendimento ------------------------ -->
                      <v-autocomplete
                        :items="empreendimentos"
                        v-model="filtro_local.empreendimento"
                        item-text="empreend_nome"
                        item-value="cod_empreendimento"
                        label="Empreendimento"
                        placeholder="Empreendimento"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        clearable
                        tabindex="0"
                        return-object
                        class="mr-2"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="linhas">
                    <v-col md="3" disabled>

                      <!-- Data Inicial ----------------------- -->
                      <DatePicker
                        id="txtVencInicial"
                        ref="txtVencInicial"
                        v-model="filtro_local.data_inicial"
                        label="Data Inicial"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>
                    <v-col md="3">
                      <!-- Data Final ----------------------- -->
                      <DatePicker
                        id="txtVencFinal"
                        ref="txtVencFinal"
                        v-model="filtro_local.data_final"
                        label="Data Final"
                        placeholder="dia/mês/ano"
                        class="mr-2"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense>
                      </DatePicker>
                    </v-col>  
                    <v-col md="6">
                      <v-autocomplete
                        v-if="lb_Funcao"
                        :items="corretores"
                        v-model="filtro_local.corretor"
                        item-text="corretor_nome"
                        item-value="cod_pessoa"
                        label="Corretor"
                        placeholder="Corretor"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        class="mt-n2 mr-n4"
                        style="padding-right: 6px!important;"
                        clearable
                        tabindex="4"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters class="linhas">
                    <v-col md="6">
                      <v-autocomplete
                        :items="array_situacao"
                        v-model="filtro_local.situacao"
                        label="Situação"
                        placeholder="Situação"
                        outlined
                        filled
                        background-color="#FFF"
                        dense
                        required
                        flat
                        class="mt-n5"
                        style="padding-right: 6px!important;"
                        clearable
                        tabindex="4"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row> -->
                </v-container>
                <v-divider></v-divider>

                  <!-- Botao Limpar ------------------------------------>
                  <div class="pa-4 d-flex justify-space-between"
                       v-bind:style="{ 'background-color':COR_SECUNDARIA }">
                    <v-btn
                      class="text-white text-capitalize"
                      :color="COR_PRINCIPAL"
                      elevation="0"
                      outlined
                      tabindex="-1"
                      :small="isMobile"
                      @click="limpa_todos()"
                      >
                      <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                        >mdi-close</v-icon
                      >
                      <span>Limpar Todos</span>
                    </v-btn>
                    
                    <!-- Botao Consultar ------------------------------------>
                    <v-btn
                      class="text-white text-capitalize"
                      :color="COR_PRINCIPAL"
                      elevation="0"
                      tabindex="-1"
                      :small="isMobile"
                      @click="busca(1)"
                      >
                      <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                        >mdi-magnify</v-icon
                      >
                      <span>Consultar</span>
                    </v-btn>
                  </div>  

              </v-menu>
          </v-container>
          <!-- ----------------------------------------------- -->

          </div>
        </v-toolbar-title>
        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>
    
      <v-row class=" mt-n11 w-100 mx-0 mb-n2">
        <!-- Grade ----------------------------------->
        <v-col cols="12" class="pa-0 mx-0 w-100">
          <v-container class="container-principal container-rounded px-0 w-100 mx-0">

            <!-- Conteiner  -->
            <v-data-table
              id="dataTable"
              ref="dataTable"
              :items="store_Simulacoes.dados"
              :headers="headers"
              :loading="loading"
              fixed-header
              dense
              :height="tableHeight"
              :hide-default-header="isMobile"
              :hide-default-footer="true"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              class="data-table"
              :server-items-length="count_dados"
              :items-per-page="20"
            >
              <template #item="{ item }">
                <tr style="width: 15%" v-if="!isMobile">
                  <td align="center">
                    <span class="text font-weight-bold" style="font-size:16px" v-bind:style="{ 'color':COR_SUBTITULO }">Nº {{ item.cod_orcamento }}</span><br>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <div 
                          class="text-menu mb-1 mt-1"
                          v-bind="attrs"
                          v-on="on">
                          <v-avatar color="#C9C9C9" size="40">
                            <v-avatar
                              size="38"
                              style="top: 0px; left: 0px"
                            >
                              <v-img
                                v-if="item.corretor_foto"
                                :src="item.corretor_foto">
                              </v-img>
                              <span 
                                v-else
                                class="iniciais">
                                {{ nomeIniciais(item.corretor_nome) }}
                              </span>
                            </v-avatar>
                          </v-avatar><br>
                        </div>
                      </template>
                      <span class="font-weight-bold" style="font-size:13px; color:#FFFFFF !important;" >Corretor / Vendedor</span><br>
                      <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_nome }}</span><br>
                      <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_celular }}</span><br>                    
                      <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_email }}</span>
                    </v-tooltip>

                        <div class="mt-n2" >
                          <span style="font-size:12px; color:#808080 !important;" >{{ formatDate(item.data_hora) }}</span><br>
                        </div>
                        <div class="mt-n2" >
                          <span style="font-size:12px; color:#808080 !important;" >{{TimeAgo(item.data_hora)}}</span>
                        </div>
                  </td>

                  <td style="width: 30%">
                    <span class="text font-weight-bold mr-2" style="font-size:16px" v-bind:style="{ 'color':COR_SUBTITULO }">{{item.cliente_nome}}</span><br>
                    <span class="mr-2" style="font-size:14px; color:#808080 !important;">Cpf:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_cpf }}</span><br>
                    <span class="mr-2" style="font-size:14px; color:#808080 !important;">Email:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_email }}</span><br>
                    <span class="mr-2" style="font-size:14px; color:#808080 !important;">Celular:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_celular }}</span>
                  </td>

                  <td style="width: 30%" class="td-avatar py-1 pl-4">
                    <v-row class="mt-2 mb-2">
                      <div>
                        <v-avatar color="#C9C9C9" size="82" rounded>
                          <v-avatar
                            size="80"
                            rounded
                            style="top: 0px; left: 0px"
                          >
                            <v-img :src="item.imagem_caminho"></v-img>
                          </v-avatar>
                        </v-avatar>
                      </div>
                      <div class="ml-2">
                        <span class="font-weight-bold ml-2" style="color:#808080 !important;">{{ item.empreend_nome }}</span><br>
                        <span class="font-weight-bold ml-2" style="color:#808080 !important;">Quadra: </span>{{ item.quadra }}<span class="font-weight-bold ml-2 color:#808080 !important;"></span>
                        <span class="font-weight-bold mr-2 ml-3" style="color:#808080 !important;">Lote:</span>{{ item.lote }}<br>
                        <span class="font-weight-bold ml-2" style="color:#808080 !important;">Área: </span>{{ item.area_metro }}<span class="font-weight-bold ml-2 color:#808080 !important;"></span>
                        <span class="font-weight-bold mr-2 ml-3" style="color:#808080 !important;">Valor:</span>R$ {{ formatNumber(item.imovel_preco_final) }}<br>
                      </div>
                    </v-row>
                  </td>

                  <td style="width: 20%; text-align:right;" >
                    <div class="d-flex" style="justify-content: left">
                      <div class="pt-2" style="text-align: right; width:90px">
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entrada:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entr: Sinal:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entr: Parc:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Parcelas:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Total:</span>
                      </div>
                      <div class="pt-2" style="text-align:right; width:125px">
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>R$ {{ formatNumber(item.entrada_total) }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.sinal }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.entrada }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.parcela }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>R$ {{ formatNumber(item.valor_total) }}
                      </div>
                    </div>
                  </td>

                  <td class="td-edit" style="width: 3%">
                    <v-btn
                      v-on:click="orcamentoRelatorio(item.cod_orcamento)"
                      icon 
                      fab
                      color="success"
                      small
                      class="btn-icon mr-5"
                      :loading="loading_relatorio == item.cod_orcamento"
                    >
                      <v-icon class="icon-action">mdi-share </v-icon>
                    </v-btn>
                  </td>
                </tr>

                <!-- MOBILE ------------------------------------------------------------------------------- -->
                <tr v-if="isMobile" class="table" style="background-color:#FFFFFF">
                  <td class="table-td pt-2 pb-3" style="background-color:#FFFFFF">

                    <div class="d-flex">
                      <div class="ml-n2 mb-2" style="width:100px" align="center">
                        <span class="text font-weight-bold" style="font-size:16px" v-bind:style="{ 'color':COR_SUBTITULO }">Nº {{ item.cod_orcamento }}</span><br>

                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <div 
                              class="text-menu mb-1 mt-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-avatar color="#C9C9C9" size="40">
                                <v-avatar
                                  size="38"
                                  style="top: 0px; left: 0px"
                                >
                                  <v-img
                                    v-if="item.corretor_foto"
                                    :src="item.corretor_foto">
                                  </v-img>
                                  <span 
                                    v-else
                                    class="iniciais">
                                    {{ nomeIniciais(item.corretor_nome) }}
                                  </span>
                                </v-avatar>
                              </v-avatar><br>
                            </div>
                          </template>
                          <span class="font-weight-bold" style="font-size:13px; color:#FFFFFF !important;" >Corretor / Vendedor</span><br>
                          <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_nome }}</span><br>
                          <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_celular }}</span><br>                    
                          <span class="" style="font-size:12px; color:#F0F0F0 !important;" >{{ item.corretor_email }}</span>
                        </v-tooltip>

                        <div class="mt-n2" >
                          <span style="font-size:12px; color:#808080 !important;" >{{ formatDate(item.data_hora) }}</span><br>
                        </div>
                        <div class="mt-n2" >
                          <span style="font-size:12px; color:#808080 !important;" >{{TimeAgo(item.data_hora)}}</span>
                        </div>
                      </div>

                      <div class="ml-2">
                        <span class="text font-weight-bold mr-2" style="font-size:16px" v-bind:style="{ 'color':COR_SUBTITULO }">{{item.cliente_nome}}</span><br>
                        <span class="mr-2" style="font-size:14px; color:#808080 !important;">Cpf:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_cpf }}</span><br>
                        <span class="mr-2" style="font-size:14px; color:#808080 !important;">Email:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_email }}</span><br>
                        <span class="mr-2" style="font-size:14px; color:#808080 !important;">Celular:</span><span style="font-size:12px; color:#808080 !important;">{{ item.cliente_celular }}</span>
                      </div>
                    </div>

<!-- ================================================================ -->
                    <div class="d-flex">
                      <div style="width:100px">
                        <v-avatar color="#A9A9A9" size="82" rounded>
                          <v-avatar
                            size="80"
                            rounded
                            style="top: 0px; left: 0px"
                          >
                            <v-img :src="item.imagem_caminho"></v-img>
                          </v-avatar>
                        </v-avatar>
                      </div>

                      <div>
                        <span class="font-weight-bold ml-0 mobile-emp" style="color:#808080 !important;">{{ item.empreend_nome }}</span><br>
                        <span class="font-weight-bold ml-0" style="color:#808080 !important;">Quadra: </span>{{ item.quadra }}<span class="font-weight-bold ml-2 color:#808080 !important;"></span>
                        <span class="font-weight-bold mr-2 ml-0" style="color:#808080 !important;">Lote:</span>{{ item.lote }}<br>
                        <span class="font-weight-bold ml-0" style="color:#808080 !important;">Área: </span>{{ item.area_metro }}<span class="font-weight-bold ml-2 color:#808080 !important;"></span>
                        <span class="font-weight-bold mr-2 ml-0" style="color:#808080 !important;">Valor:</span>R$ {{ formatNumber(item.imovel_preco_final) }}<br>
                      </div>
                    </div>
<!-- ================================================================ -->

                    <div class="d-flex" style="justify-content: left">
                      <div class="pt-2" style="text-align: right; width:110px">
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entrada:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entr: Sinal:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Entr: Parc:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Parcelas:</span><br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;">Total:</span>
                      </div>
                      <div class="pt-2" style="text-align:right; width:150px">
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>R$ {{ formatNumber(item.entrada_total) }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.sinal }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.entrada }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>{{ item.parcela }}<br>
                        <span class="font-weight-bold mr-2" style="font-size:13px; color:#808080 !important;"></span>R$ {{ formatNumber(item.valor_total) }}
                      </div>
                      <div class="td-edit" align="right" style="width:100px">
                        <br>
                        <br>
                        <v-btn
                          v-on:click="orcamentoRelatorio(item.cod_orcamento)"
                          class="mx-2"
                          fab
                          dark
                          small
                          color="success"
                          :loading="loading_relatorio == item.cod_orcamento"
                        ><v-icon class="icon-action">mdi-share</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-container>
        </v-col>
        <!-- Fim Grade ----------------------------------->
      </v-row>

      <!-- Paginacao ------------------------------ -->
      <div class="paginacao d-flex justify-end align-center" style="background-color: #FFFFFF;">
        <span style="color:grey; font-size:14px" class="mr-3">{{count_dados}} linhas</span>
        <v-pagination
          id="pagination"
          ref="pagination"
          v-model="currentPage"
          :length="store_Simulacoes.paginas"
          total-visible="5"
          @input="handlePageChange"
          aria-controls="dataTable"
          class="mt-n1"
          style="color:grey" />
      </div>
  </v-container>
</template>

<script>
import { API, baseURL } from "../../../services/API"
import store_usuario from "../../../store/store_usuario"
import store_Simulacoes from "./store_Simulacoes";
import store_Corretor from "../../Corretores/store_Corretor";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, COR_SECUNDARIA_2 } from "../../../services/constantes";
import { formatNumber, formatDate, nomeIniciais, TimeAgo } from "../../../services/funcoes";
import { OrcamentoRelatorio, ReservaRelatorio, PropostaRelatorio } from "../../../services/global"
import DatePickerComponent from "../../ComponentesGlobais/Date-Picker/date-picker-component.vue";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";


export default {
  name: "SimulacoesConsulta",
  components: {
    // DatePickerComponent,
    DatePicker
  },

  data() {
    return {
      store_usuario       : store_usuario,
      store_Simulacoes    : store_Simulacoes,
      store_Corretor      : store_Corretor,

      formatNumber        : formatNumber,
      formatDate          : formatDate,
      TimeAgo             : TimeAgo,
      nomeIniciais        : nomeIniciais,

      empreendimentos     : [],
      corretores          : [],

      loading             : false,
      loading_relatorio   : 0,
      lb_Funcao           : false,

      currentPage         : 1,
      count_dados         : null,

      COR_PRINCIPAL       : COR_PRINCIPAL,
      COR_SUBTITULO       : COR_SUBTITULO,
      COR_SECUNDARIA      : COR_SECUNDARIA,
      COR_SECUNDARIA_2    : COR_SECUNDARIA_2,
      accentColor         : '#f00',

      menu                : false,

      // Filtro Local da Tela de Consulta --------
      filtro_local: {
        cliente_nome        : null,
        cod_corretor        : null,
        corretor            : null,
        cod_empreendimento  : null,
        empreendimento      : null,
        data_inicial        : null,
        data_final          : null,
      },

      /* Título container-rounded */
      headers: [  { text: "", value: "Negociação", sortable: true },
                  // { text: "Corretor", value: "Corretor", sortable: true },
                  { text: "Cliente", value: "Cliente", sortable: true },
                  { text: "Imóvel", value: "Imóvel", sortable: true },
                  { text: "Pagamento", value: "Pagamento", sortable: true },
                  { text: ""},
               ],

      data_picker : {
        'min-width':'170px !important',
        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        'margin-top':'-10px !important',
        //'color':'red'
      }

    };
  },

  async created() {
    this.lb_Funcao = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
    if (this.lb_Funcao)
      this.busca_Corretores();
    this.busca(1);
    this.getEmpreendimento();

  },

  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 70;
      else
        return window.innerHeight - 149 - 70;
    }

  },

  // mounted() {
    // onResize();
    // window.addEventListener("resize", this.onResize);
  // },

  // destroyed() {
    // window.removeEventListener("resize", this.onResize);
  // },
  
  methods: {
    async limpa_todos() {
      for(let prob in this.filtro_local){
        //console.log('limpando: ' , this.filtro_local[prob])
        this.filtro_local[prob] = null
      }
    },
    async getEmpreendimento() {
      const resp = await API.get(`empreendimento`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },

    async busca_Corretores() {
      var lo_params = {};
      this.corretores = await this.store_Corretor.CorretoresGet(lo_params);
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.busca(value);
    },

    async busca(page) {
      this.loading = true;
      this.menu    = false;
      this.store_Simulacoes.filtro = {...this.filtro_local};
      if (this.store_Simulacoes.filtro.cliente_nome) {
        //console.log("this.store_Simulacoes.filtro.filtro", this.store_Simulacoes.filtro.cliente_nome)
        this.store_Simulacoes.filtro.cliente_nome = this.store_Simulacoes.filtro.cliente_nome.trim()
      }
      var lo_params = { page: page, ...this.store_Simulacoes.filtro };
      // Limpando o objeto Empreendimento para enviar a API e deixando somente os codigos
      lo_params.cod_empreendimento   = null;
      if (this.store_Simulacoes.filtro.empreendimento) {
        lo_params.cod_empreendimento = this.store_Simulacoes.filtro.empreendimento.cod_empreendimento;
        lo_params.empreendimento     = null;
      }
      // Limpando o objeto Corretor para enviar a API e deixando somente os codigos
      lo_params.cod_corretor   = null;
      if (this.store_Simulacoes.filtro.corretor) {
        lo_params.cod_corretor = this.store_Simulacoes.filtro.corretor.cod_pessoa;
        lo_params.corretor     = null;
      }
      if (!this.lb_Funcao) {
        lo_params.cod_corretor = store_usuario.user.cod_pessoa;
      }
      console.log("🚀 ~ busca ~ lo_params:", lo_params)
      // return
      let la_JSON
      la_JSON = await this.store_Simulacoes.SimulacoesGet(lo_params);
      this.count_dados = la_JSON.count;
      if (la_JSON.rows && la_JSON.rows.length > 0)  {
        la_JSON.rows.map((value) => {
          value.imagem_caminho  = value.imagem_caminho ? baseURL + value.imagem_caminho : null;
          value.corretor_foto   = value.corretor_foto ? baseURL + value.corretor_foto : null;
        })
        this.store_Simulacoes.dados   = la_JSON.rows;
        this.store_Simulacoes.paginas = la_JSON.paginas;
      }
      else
        this.store_Simulacoes.dados = [];
      this.loading = false;

      // Posiciona o Scrool no começo da tabela 
      try {
        this.$vuetify.goTo('.data-table tbody tr',
                            { offset: 60,
                              container: '.v-data-table__wrapper'
                            }
                          )
      }
      catch (e) {
      }
      
    },

    async orcamentoRelatorio(cod_orcamento) {
      this.loading_relatorio = cod_orcamento;
      const ls_Resp = await OrcamentoRelatorio(cod_orcamento);
      this.loading_relatorio = 0;
    },

    abriuConsulta(val) {
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = {...this.store_Simulacoes.filtro};
        });
      }
    },

    pagination() {
      return { totalItems: 100 }
    },

    // EXEMPLO    
       // onResize() {
        //   if (this.isMobile)
        //     this.tableHeight = window.innerHeight - this.$refs.toolbar.clientHeight + 30 - 90;
        //   else
        //     this.tableHeight = window.innerHeight - this.$refs.toolbar.clientHeight - 90;

        //   if (window.innerWidth < 769)
        //     this.isMobile = true;
        //   else
        //     this.isMobile = false;
        // },
        // toggleAll() {
        //   if (this.selected.length) this.selected = []
        //   else this.selected = this.desserts.slice()
        // },
        // changeSort(column) {
        //   console.log(column);
        //   if (this.pagination.sortBy === column) {
        //     this.pagination.descending = !this.pagination.descending
        //   } else {
        //     this.pagination.sortBy = column
        //     this.pagination.descending = false
        //   }
        // }

  },
};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.date-picker {
  max-width: 200px!important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.v-btn {
  display: inline-block;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/
.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-fundo {
  height: calc(100vh -30px) !important;
}

.container-rounded {
  background: #fff !important;
  border-radius: 12px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

#virtual-scroll-table-tablet {
  display: none;
}

.table {
  display: block;
  table-layout: fixed;
  overflow: hidden;
  width: 100% !important;
  min-height: 300px !important;
}

.table-td {
  display: block;
  table-layout: fixed;
  overflow: hidden;
  width: 100% !important;
  min-height: 330px !important;
}

.card-container {
  padding: 16px!important;
}

.false-filter {
  background-color:#6C87A2;
  height: 38px!important;
  min-width: 350px!important;
  /* max-width: 900px!important; */
  border-radius: 5px;
}

.false-text {
  width: calc(100%);
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #C9CBCA;
  cursor: pointer;
  overflow-x: hidden;
}

.row {
  width: 100%!important;
}

.false-icon {
  cursor: pointer;
}

.v-select__slot {
  display: none !important;
}

/* ::v-deep .v-data-footer {
  align-content: center;
  color: var(--COR_PRINCIPAL) !important;  
  font-size:14px !important;
  height: 35px;
  padding-top: 10px !important;
}

::v-deep .v-data-footer__select {
  display: none;
} */

.theme--light.v-data-table {
  border-radius: 12px 12px 0px 0px !important;
}

</style>